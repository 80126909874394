import React, { useEffect } from 'react';
import {Mobileno} from './pages/mobileno.js';
import {Otp} from './pages/otp.js';
import {Location} from './pages/location.js';
import {Sorry} from './pages/sorry.js';
import {Congratulations} from './pages/congratulations.js';
import { BrowserRouter as Router, Switch, Route, Routes, useNavigate  } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export const Routes1 = () => {
    return(
        <Router>
            <Routes>
                <Route path="/:id" element={<Mobileno />} />
                <Route path="/otp" element={<Otp />} />
                <Route path="/congratulations" element={<Congratulations />} />Location
                <Route path="/location" element={<Location />} />
                <Route path="/sorry" element={<Sorry />} />
            </Routes>
        </Router>
    );
}