
import React, { useState, useEffect } from 'react';

export const Location = () => {
 

  return (
    <div className="mobileno_page_wrapper container">
      <img src='./obj.png' className='obj_1'/>
      <img src='./obj2.png' className='obj_2'/>
      <img src='./obj3.png' className='obj_3'/>
      <div className='mobile_page_logo'>
        <img src="./logo.png"/>
      </div>
      <div className='mobile_page_mainimg'>
        <img src="./burger.jpg"/>
      </div>
        <div className="page_title">
        <h2>LIST OF AVAILABLE 
        LOCATIONS</h2>
        </div>

        <div className='ava_location_wrapper'>
        <div class="ava_location">
            <p>Adajan</p>
            <p>Vesu</p>
            <p>Rander</p>
            <p>City Light</p>
            <p>LP Savani</p>
            <p>Ring Road</p>
        </div>  
        </div>
      <div className="tc location">Terms & Conditions</div>
    </div>
  );
}