import React, { useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';
import { OTP } from "../service/api.js";
import ErrorSnackbar from '../components/ErrorSnackbar.js';
import SuccessSnackbar from '../components/SuccessSnackbar.js';
import { useNavigate } from 'react-router-dom';

export const Otp = () => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState(''); 
    const [errotp, errsetOtp] = useState(''); 
    const [ExceptionError, setExceptionError] = useState([]);
    const [successMessages, setSuccessMessages] = useState([]);
    function handleExceptionError(otperr) {
      // alert("sf");
      setExceptionError(ExceptionError => [
        ...ExceptionError,
        { id: Date.now(), message: otperr },
      ]);
    }
  
    function handleExceptionSuccessMessages(otperr) {
      setSuccessMessages(successMessages => [
        ...successMessages,
        { id: Date.now(), message: otperr },
      ]);
    }
  
    function clearErrors(id) {
      setExceptionError(prevMessages =>
        prevMessages.filter(msg => msg.id !== id)
      );
    }
    function clearSuccess(id) {
      setSuccessMessages(prevMessages =>
        prevMessages.filter(msg => msg.id !== id)
      );
    }

    const handleSubmit = async (e) => {
      e.preventDefault();
      let start;
      if (otp == "") {
          errsetOtp("OTP is required");
          start = 0;
      }else{
        start = 1;
        errsetOtp(" ");
      }
      if (start == 1){
          
        const res = await OTP(otp);
        console.log(res);
  
        if (res.status == 200) {
          var data = res.data;
          console.log(data);
          localStorage.setItem("CouponCode", data[0].CouponCode);
          let otperr;
          if (data[0].Response == 3) {
              otperr = "Expired OTP";
          }
          if (data[0].Response == 2) {
            otperr = "Invalid OTP";
        }
        if (data[0].Response == 1) {
          otperr = "verified";
        }
        if (data[0].Response == 1) {
          handleExceptionSuccessMessages(otperr);
          setTimeout(() => {
            navigate('/congratulations')
          }, 1500);
        }else{
          handleExceptionError(otperr);
        }
        } else {
          handleExceptionError(res)
        }
      } else {
        console.log("Fill the required");
      }
    };
  return (
    <div className="mobileno_page_wrapper container">
       <ErrorSnackbar errorMessages={ExceptionError} onClearErrors={clearErrors} />
      <SuccessSnackbar successMessages={successMessages} onclearSuccess={clearSuccess} />
      <img src='./obj.png' className='obj_1'/>
      <img src='./obj2.png' className='obj_2'/>
      <img src='./obj3.png' className='obj_3'/>
      <div className='mobile_page_logo'>
        <img src="./logo.png"/>
      </div>
      <div className='mobile_page_mainimg'>
        <img src="./burger.jpg"/>
      </div>
      <form className='otp_form' onSubmit={handleSubmit}>
        <label><p>Enter The OTP</p>
        <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      renderSeparator={<span className="gapbetween"> </span>}
                      placeholder=''
                      renderInput={(props) => <input {...props} />}
                    />
                    {errotp && <span className="err">{errotp}</span>}
                    {/* <p>*This Number Is Already Exists</p> */}
        </label>
        <div className='submit_btn'>
          <button type='submit'>Verify</button>
        </div>
      </form> 
    </div>
  );
}