import React, { useState, useEffect } from "react";
import { Qrcode, Getcity, Mobile } from "../service/api.js";
import ErrorSnackbar from '../components/ErrorSnackbar.js';
import SuccessSnackbar from '../components/SuccessSnackbar.js';
import { useParams } from 'react-router-dom'; 
import { useNavigate } from 'react-router-dom';

export const Mobileno = () => {
  const navigate = useNavigate();

  const [city, setCity] = useState("");
  const [errcity, seterrCity] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [errmobileNumber, seterrMobileNumber] = useState("");
  const [ExceptionError, setExceptionError] = useState([]);
  const [successMessages, setSuccessMessages] = useState([]);
  const [allcity, setallcity] = useState([]);
  const [ipAddress, setIpAddress] = useState("");
  const [IpAddresstr, setIpAddresstr] = useState(false);
  let { id } = useParams();

  useEffect(() => {
    // Fetch the user's IP address using a public API
    fetch('https://api64.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setIpAddress(data.ip);
        setIpAddresstr(true);
      })
      .catch((error) => {
        console.error('Error fetching IP address:', error);
      });
  }, []);

  const [code, setCode] = useState('');

  useEffect(() => {
    // Get the current page's URL
    const currentUrl = window.location.href;

    // Use JavaScript to extract the code from the URL
    const segments = currentUrl.split('/');
    const lastSegment = segments[segments.length - 1];

    // Update the code state with the extracted code
    setCode(lastSegment);
  }, []);
  console.log(code);

  function handleExceptionError(res) {
    // alert("sf");
    setExceptionError(ExceptionError => [
      ...ExceptionError,
      { id: Date.now(), message: "Error" },
    ]);
  }

  function handleExceptionSuccessMessages(data) {
    console.log(data[0].Response);
    setSuccessMessages(successMessages => [
      ...successMessages,
      { id: Date.now(), message: data[0].Response },
    ]);
  }

  function clearErrors(id) {
    setExceptionError(prevMessages =>
      prevMessages.filter(msg => msg.id !== id)
    );
  }
  function clearSuccess(id) {
    setSuccessMessages(prevMessages =>
      prevMessages.filter(msg => msg.id !== id)
    );
  }

  const handleCityChange = (e) => {
    setCity(e.target.value);
  };

  const handleMobileNumberChange = (e) => {
    setMobileNumber(e.target.value);
  };
  useEffect(() => {
    const getdata = async (ipAddress) => { 
      
      console.log(ipAddress);
      try {
    const res = await Qrcode(id, ipAddress);
    if (res.status == 200) {
      try {
        const resp = await Getcity();
        if (resp){
          console.log(resp);
          setallcity(resp);
        }
      }catch (error){
        console.error("Error:", error);
      }
    } else {
      // handleExceptionError(res.response.data.ErrorMessage)
    } 
    } catch (error) {
      console.error("Error:", error);
    }
  }
  if (IpAddresstr == true){
    getdata(ipAddress);
  }
}, [IpAddresstr]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let start;
    if (city == "" || mobileNumber == "") {
      if (city == "") {
        seterrCity("City is required");
        start = 0;
      } else {
        seterrCity("");
      }
      if (mobileNumber == "") {
        seterrMobileNumber("Mobile No. is required");
        start = 0;
      } else {
        seterrMobileNumber("");
      }
    } else {
      start = 1;
      seterrMobileNumber(" ");
      seterrCity(" ");
    }
    if (start == 1){
        
      const res = await Mobile(city, mobileNumber, ipAddress, id);
      console.log(res);

      if (res.status == 200) {
        var data = res.data;
        console.log(data);
        localStorage.setItem("otpID", data[0].ID);
        handleExceptionSuccessMessages(data);
        setTimeout(() => {
          navigate('/otp')
        }, 1500);
      } else {
        handleExceptionError(res)
      }
    } else {
      console.log("Fill the required");
    }
  };
console.log(allcity);
  return (
    <div className="mobileno_page_wrapper container">
       <ErrorSnackbar errorMessages={ExceptionError} onClearErrors={clearErrors} />
      <SuccessSnackbar successMessages={successMessages} onclearSuccess={clearSuccess} />
      <img src="./obj.png" className="obj_1" />
      <img src="./obj2.png" className="obj_2" />
      <img src="./obj3.png" className="obj_3" />
      <div className="mobile_page_logo">
        <img src="./logo.png" />
      </div>
      <div className="mobile_page_mainimg">
        <img src="./burger.jpg" />
      </div>
      <form onSubmit={handleSubmit}>
        <label>
          <p>Enter Your City</p>
          <select value={city} onChange={handleCityChange}>
            <option></option>
            {allcity.map((city) => (
            // <option>{item}</option>
            <option key={city.CityID} value={city.CityID}>{city.CityName}</option>
            ))}
          </select>
          {errcity && <span className="err">{errcity}</span>}
        </label>
        <label>
          <p>Enter Your Mobile Number To Get This Offer</p>
          <input
            type="text"
            value={mobileNumber}
            onChange={handleMobileNumberChange}
          />
          {errmobileNumber && <span className="err">{errmobileNumber}</span>}
        </label>
        <div className="submit_btn">
          <button type="submit">Get Offer</button>
        </div>
      </form>
    </div>
  );
};
