
import React, { useState, useEffect } from 'react';

export const Sorry = () => {
 

  return (
    <div className="mobileno_page_wrapper container">
      <img src='./obj.png' className='obj_1'/>
      <img src='./obj2.png' className='obj_2'/>
      <img src='./obj3.png' className='obj_3'/>
      <div className='mobile_page_logo'>
        <img src="./logo.png"/>
      </div>
      <div className='mobile_page_mainimg'>
        <img src="./burger.jpg"/>
      </div>
      <div className="page_title sorry">
        <h2>SORRY!</h2>
        <p>You Have Already Used This Offer.
Please Try Another Offers.</p>
<div className="tc">Terms & Conditions</div>

      </div>
    </div>
  );
}