
import React, { useState, useEffect } from 'react';

export const Congratulations = () => {
  const [CouponCode, setCouponCode] = useState(localStorage.getItem("CouponCode"));
 if (!CouponCode) {
  setCouponCode("Coupon not found");
 }

  return (
    <div className="mobileno_page_wrapper container">
      <img src='./obj.png' className='obj_1'/>
      <img src='./obj2.png' className='obj_2'/>
      <img src='./obj3.png' className='obj_3'/>
      <div className='mobile_page_logo'>
        <img src="./logo.png"/>
      </div>
      <div className='mobile_page_mainimg'>
        <img src="./burger.jpg"/>
      </div>
      <div className="page_title">
        <h2>Congratulations</h2>
        <p>Now You Can Get This Offer
            By Using This Code</p>
      </div>
      <div className="offer_code">{CouponCode}</div>
      <div className="tc">Terms & Conditions</div>
    </div>
  );
}