import axios from "axios";
import qs from 'qs';
const api_base= "http://app.ajays.co.in/CAMPAIGN/api/Campaign/";
export const Qrcode = async (id, ipAddress) => {
  // const formData = qs.stringify(requestData);
 
  const requestData = {
    QRCode: id,
    IPAddress: ipAddress,
  };
 
  const formData = qs.stringify(requestData);
    try {
      const response =  axios.post(api_base + "QRCodeScan",formData,  {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded', // Set the content type
        },
        // body: formData.toString(),
      });
      return response;
    } catch (error) {
      return error;
    }
  };
  export const Getcity = async () => {
   
    var urlencoded = new URLSearchParams();

    var requestOptions = {
      method: 'POST',
      body: urlencoded,
      redirect: 'follow'
    };
    try {
      const response = await fetch(api_base + "GetCityList", requestOptions);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      const data = await response.json(); // Parse the JSON response
      return data; // Return the parsed JSON object
    } catch (error) {
      return error;
    }
    };

    export const Mobile = async (city, mobileNumber, ipAddress, id) => {

     
      const requestData = {
        QRCode: id,
        IPAddress: ipAddress,
        CampaignID: 0,
        MobileNo: mobileNumber,
        CityID: city, 
      };
     
      const formData = qs.stringify(requestData);
        try {
          const response =  axios.post(api_base + "MobileNoEnter",formData,  {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded', // Set the content type
            },
            // body: formData.toString(),
          });
          return response;
        } catch (error) {
          return error;
        }
      };
      
    export const OTP = async (otp) => {

     
      const requestData = {
        OTP: otp,
        ID: localStorage.getItem("otpID"),
      };
     
      const formData = qs.stringify(requestData);
        try {
          const response =  axios.post(api_base + "VerifyOTP",formData,  {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded', // Set the content type
            },
            // body: formData.toString(),
          });
          return response;
        } catch (error) {
          return error;
        }
      };